<template>
  <div class="d-flex flex-column px-2 pt-15 pb-10" style="background-color:#728FAC ; color: white">
    <div class="d-inline-flex justify-center text-h4">
      {{ t('form|heading') }}
    </div>

    <div class="d-inline-flex justify-center mt-5">
      <v-form ref="form" v-model="formValid" @submit.prevent="send" style="max-width: 400px; min-width:35%;">
        <v-spacer style="height: 10px;"></v-spacer>
        <v-text-field v-model="name" :label="t('form|name')" name="name" variant="outlined"
          prepend-inner-icon="mdi-account" clearable
          :rules="[rules.required(name, t('form|required')), rules.max(name, 50, t('form|max', { maxNum: 50 }))]" counter
          maxlength="50" :readonly="loading">
        </v-text-field>
        <v-spacer style=" height: 10px;"></v-spacer>
        <v-text-field v-model="connectionInfo" :label="t('form|contact')" name="connectionInfo" variant="outlined"
          prepend-inner-icon="mdi-cellphone-wireless" clearable
          :rules="[rules.required(connectionInfo, t('form|required')), rules.max(connectionInfo, 50, t('form|max', { maxNum: 50 }))]"
          counter maxlength="50" :readonly="loading">
        </v-text-field>
        <v-spacer style="height: 10px;"></v-spacer>
        <v-textarea v-model="notes" :label="t('form|notes')" name="notes" variant="outlined"
          prepend-inner-icon="mdi-message-bulleted" clearable
          :rules="[rules.required(notes, t('form|required')), rules.max(notes, 1000, t('form|max', { maxNum: 1000 }))]"
          counter maxlength="1000" :readonly="loading">
        </v-textarea>

        <div class="d-flex flex-column justify-center">
          <div class="d-inline-flex justify-center mb-5">
            <v-alert v-if="alert.message" :type="alert.type" :text="alert.message">
            </v-alert>
          </div>
          <div class="d-inline-flex justify-center">
            <v-btn :disabled="!formValid" :loading="loading" color="deep-orange-lighten-1" size="large" type="submit"
              variant="elevated" width="120" append-icon="mdi-send">{{ t('form|send') }}</v-btn>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Database from "../js/Database";

export default defineComponent({
  name: "FormComponent",

  setup() {
    return { t: useI18n().t };
  },

  data: () => {
    return {
      formValid: false,
      loading: false,
      name: "",
      connectionInfo: "",
      notes: "",
      alert: {},
      rules: {
        required: (v, text) => !!v || text,
        max: (v, maxNum, text) => (v || '').length <= maxNum || text,
      }
    };
  },

  methods: {
    async send() {
      const { valid } = await this.$refs.form.validate()

      if (!valid) {
        return;
      }

      this.loading = true;

      const item = {
        name: this.name,
        connectionInfo: this.connectionInfo,
        notes: this.notes
      };

      try {
        await Database.addObject("CONTACTS", item);

        this.$refs.form.reset();
        this.$refs.form.resetValidation();

        this.showAlert("Thank you for contacting us.", "success");
      } catch (error) {
        this.showAlert("Sorry for not saving your request or suggesstion.", "error");
      }

      this.loading = false
    },

    showAlert(message, type) {
      this.alert = { message, type };

      var myThis = this;

      setTimeout(function () { myThis.alert = {}; }, 5000);
    }
  }
});
</script>

<style scoped></style>