<template>
  <div class="px-5 pt-15" style="background-color: #1bdd9b; color: white">
    <div class="d-flex justify-center text-h4">
      {{ t("references|heading") }}
    </div>

    <div class="d-flex justify-center px-5  pb-10">
      <v-row no-gutters>
        <v-col v-for="(item, index) in items" v-model="items" :key="index">
          <v-card class="pt-8 px-5 text-center" outlined tile elevation="0" style="background-color: transparent;">
            <v-btn variant="text" :href="item.url">
              <v-img :src="getImg(item.avatar)" width="auto" height="auto"></v-img>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "ReferencesComponent",

  setup() {
    return { t: useI18n().t };
  },

  data: () => {
    return {
      items: [{
        avatar: "innovance.png",
        url: "https://innovance.com.tr/",
      }]
    };
  },

  methods: {
    getImg(img) {
      return require("../assets/" + img);
    },
  },
});
</script>

<style scoped></style>