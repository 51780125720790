<template>
  <v-footer class="justify-center pb-5" padless color="white">
    <div class="d-flex text-h6 pt-3">
      {{ heading }}
    </div>
    <div class="d-flex pl-3">
      <v-avatar size="35">
        <v-img :src="getImg('logo.jpg')"></v-img>
      </v-avatar>
    </div>
  </v-footer>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "FooterComponent",

  setup() {
    return { t: useI18n().t };
  },

  data: () => {
    return {
      heading: "Copyright @ Odeepa Soft",
    };
  },

  methods: {
    getImg(img) {
      return require("../assets/" + img);
    }
  }
});
</script>

<style scoped></style>