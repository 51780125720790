<template>
  <div class="d-flex flex-column align-center px-5 pt-15">
    <v-row no-gutters>
      <v-card class="text-center" outlined tile elevation="0">
        <div class="d-flex justify-center text-h5">
          {{ t('info|heading') }}
        </div>
        <div class="d-flex justify-center pt-3">
          {{ t('info|subheading') }}
        </div>
      </v-card>
    </v-row>
  </div>

  <div class="d-flex justify-center px-5 pt-8 pb-15">
    <v-row no-gutters>
      <v-col v-for="(item, index) in items" v-model="items" :key="index">
        <v-card class="pt-8 px-5 text-center" outlined tile elevation="0">
          <div class="d-flex justify-center">
            <div class="d-inline-flex mr-2">
              <v-icon :color=item.iconColor size="x-large">{{ item.icon }}</v-icon>
            </div>
            <div class="d-inline-flex ml-2 text-h6">
              {{ t(item.title) }}
            </div>
          </div>
          <div class="d-flex justify-center pt-3">
            {{ t(item.desc) }}
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "InfoComponent",

  setup() {
    return { t: useI18n().t };
  },

  data: () => {
    return {
      items: [{
        iconColor: 'light-blue-darken-3',
        icon: 'mdi-application-braces-outline',
        title: 'info|software-title',
        desc: 'info|software-desc'
      }, {
        iconColor: 'orange-darken-3',
        icon: 'mdi-list-status',
        title: 'info|analysis-title',
        desc: 'info|analysis-desc'
      }, {
        iconColor: 'teal-darken-1',
        icon: 'mdi-file-tree',
        title: 'info|project-title',
        desc: 'info|project-desc'
      }]
    };
  },

  methods: {}
});
</script>

<style scoped></style>