<template>
  <div class="d-flex justify-center text-h4 px-5 pt-15">
    {{ t("team|heading") }}
  </div>

  <v-row no-gutters class="pb-10">
    <v-col v-for="(item, index) in items" v-model="items" :key="index">
      <v-card class="pt-8 px-5 text-center" outlined tile elevation="0">
        <div class="d-flex justify-center pt-3">
          <v-avatar size="250">
            <v-img :src="getImg(item.avatar)" width="auto" height="auto"></v-img>
          </v-avatar>
        </div>
        <div class="d-flex justify-center pt-3 text-h5">
          {{ item.name }}
        </div>
        <div class="d-flex justify-center pt-3">
          {{ item.title }}
        </div>
        <div class="d-flex justify-center">
          <div class="d-inline-flex mr-1">
            <v-btn icon variant="text" :href="item.urlLinkedin">
              <v-avatar size="35">
                <v-img :src="getImg('linkedin.png')"></v-img>
              </v-avatar>
            </v-btn>
          </div>
          <div class="d-inline-flex ml-1 text-h6">
            <v-btn icon variant="text" :href="item.urlMail">
              <v-icon large color="blue-grey-darken-1">
                mdi-email
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "TeamComponent",

  setup() {
    return { t: useI18n().t };
  },

  data: () => {
    return {
      items: [
        {
          avatar: "asli.jpg",
          name: "Aslı Keskin ŞEN",
          title: "Founder & CEO",
          urlLinkedin:
            "https://www.linkedin.com/in/asl%C4%B1han-keskin-%C5%9Fen-73834893/",
          urlMail: "mailto:aslihankskn@gmail.com",
        },
        {
          avatar: "orhan.jpg",
          name: "Orhan ŞEN",
          title: "Founder & CTO",
          urlLinkedin: "https://www.linkedin.com/in/orhansen/",
          urlMail: "mailto:orhansen35@gmail.com",
        },
      ],
    };
  },

  methods: {
    getImg(img) {
      return require("../assets/" + img);
    },
  },
});
</script>

<style scoped></style>