import { initializeApp } from "firebase/app";
import { child, get, getDatabase, ref, set } from "firebase/database";

var firebaseConfig = {
  apiKey: "AIzaSyA9EpR4YkjmYU60phfgEWVdCiw7O6a8N4s",
  authDomain: "odeepa-soft.firebaseapp.com",
  projectId: "odeepa-soft",
  storageBucket: "odeepa-soft.appspot.com",
  messagingSenderId: "1099137355062",
  appId: "1:1099137355062:web:b60f83db7ac1caf0d5f797",
  measurementId: "G-2Y8MRZBKFB",
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

function _getUuid() {
  var date = new Date();
  var randomNumber = Math.floor(Math.random() * 10000);
  let uuid =
    date.toISOString().replace(".", ":").replace("T", "_").replace("Z", "_") +
    randomNumber;
  return uuid;
}

class Database {
  constructor() {
    this.getObjectList = this.getObjectList.bind(this);
    this.getObject = this.getObject.bind(this);
    this.addObject = this.addObject.bind(this);
    this.updateObject = this.updateObject.bind(this);
    this.addItem = this.addItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  getObjectList(tableName) {
    return new Promise((resolve, reject) => {
      try {
        get(child(ref(db), tableName))
          .then((snap) => {
            let list = [];

            if (snap.exists()) {
              Object.entries(snap.val()).map((item) => {
                let myItem = {};

                myItem["id"] = item[0];

                Object.entries(item[1]).map(([key, value]) => {
                  myItem[key] = value;
                });

                list.push(myItem);
              });
            }

            resolve(list);
          })
          .catch((error) => {
            console.error(error);

            reject(error);
          });
      } catch (error) {
        console.error(error);

        reject(error);
      }
    });
  }

  getObject(tableName, id) {
    return new Promise((resolve, reject) => {
      try {
        get(child(ref(db), tableName + "/" + id))
          .then((snap) => {
            let item = {};

            if (snap.exists()) {
              item = snap.val();
            }

            resolve(item);
          })
          .catch((error) => {
            console.error(error);

            reject(error);
          });
      } catch (error) {
        console.error(error);

        reject(error);
      }
    });
  }

  addObject(tableName, object) {
    return new Promise((resolve, reject) => {
      try {
        const uuid = _getUuid();

        set(ref(db, tableName + "/" + uuid), object)
          .then(() => {
            resolve(uuid);
          })
          .catch((error) => {
            console.error(error);

            reject(error);
          });
      } catch (error) {
        console.error(error);

        reject(error);
      }
    });
  }

  updateObject(tableName, id, object) {
    return new Promise((resolve, reject) => {
      try {
        this.getObject(tableName, id).then((item) => {
          try {
            if (
              item === undefined ||
              item === null ||
              Object.keys(item).length === 0
            ) {
              throw new Error("Updated object is not found.");
            }

            set(ref(db, tableName + "/" + id), object)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                console.error(error);

                reject(error);
              });
          } catch (error) {
            console.error(error);

            reject(error);
          }
        });
      } catch (error) {
        console.error(error);

        reject(error);
      }
    });
  }

  addItem(tableName, id, key, value) {
    return new Promise((resolve, reject) => {
      try {
        this.getObject(tableName, id).then((item) => {
          try {
            if (
              item === undefined ||
              item === null ||
              Object.keys(item).length === 0
            ) {
              throw new Error("Updated object is not found.");
            }

            Object.assign(item, { [key]: value });

            set(ref(db, tableName + "/" + id), item)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                console.error(error);

                reject(error);
              });
          } catch (error) {
            console.error(error);

            reject(error);
          }
        });
      } catch (error) {
        console.error(error);

        reject(error);
      }
    });
  }

  updateItem(tableName, id, key, value) {
    return new Promise((resolve, reject) => {
      try {
        this.getObject(tableName, id).then((item) => {
          try {
            if (
              item === undefined ||
              item === null ||
              Object.keys(item).length === 0
            ) {
              throw new Error("Updated object is not found.");
            }

            if (item[key] === undefined) {
              throw new Error("Updated item is not found.");
            }

            Object.assign(item, { [key]: value });

            set(ref(db, tableName + "/" + id), item)
              .then(() => {
                resolve(true);
              })
              .catch((error) => {
                console.error(error);

                reject(error);
              });
          } catch (error) {
            console.error(error);

            reject(error);
          }
        });
      } catch (error) {
        console.error(error);

        reject(error);
      }
    });
  }
}

const dbInstance = new Database();
export default dbInstance;
