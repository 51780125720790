<template>
  <v-carousel cycle height="auto" style="w-100" hide-delimiters hide-delimiter-background :show-arrows="false">
    <v-carousel-item v-for="(item, i) in items" :key="i" :src="getImg(item.src)">
      <div class="d-flex" style="width:100%; height: 100%;">
        <div class="d-inline-flex align-start" style="width:10%; margin-right:-5%; margin-top: 2%; padding-left: 2%;">
          <v-img :src="getImg(this.logoUrl)" aspect-ratio="2" min-height="40" min-width="40" position="left">
          </v-img>
        </div>
        <div class="d-inline-flex justify-center" style="width:100%;">
          <v-responsive style="" class="d-flex align-center justify-center text-center ma-auto h-100 w-100"
            max-width="700" max-height="800">
            <div class="intro-heading">
              {{ t('intro|heading') }}
            </div>
            <div class="intro-subheading">
              {{ t('intro|subheading') }}
            </div>
          </v-responsive>
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'IntroComponent',

  setup() {
    return { t: useI18n().t };
  },

  data: () => {
    return {
      logoUrl: "logo.png",
      items: [{ src: "slide-1.jpg" }, { src: "slide-2.jpg" },
      { src: "slide-3.jpg" }, { src: "slide-4.jpg" }]
    };
  },

  methods: {
    getImg(img) {
      return require("../assets/" + img);
    }
  }
});
</script>

<style scoped>
.intro-heading {
  font-size: calc(30px + (20) * ((100vw - 300px) / (1600 - 300)));
  color: #fff;
  font-weight: 600;
  margin-bottom: 3%;
}

.intro-subheading {
  font-size: calc(12px + (8) * ((100vw - 300px) / (1600 - 300)));
  color: rgb(188, 191, 194);
  font-weight: 400;
  font-family: Georgia, serif;
  font-style: italic;
}
</style>