export default {
  "intro|heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODEEPA SOFT"])},
  "intro|subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide software, analysis and project management consultancy for your all software projects."])},
  "info|heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software is a passion for us"])},
  "info|subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact our expertise for quality solutions to your projects."])},
  "info|software-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software"])},
  "info|software-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide end-to-end software and architectural support to digital transformation projects. We can implement softwares to your new requirements or redesign your existing projects. We help you save time and resources by providing you with the most suitable architectural solutions with our experience."])},
  "info|analysis-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis & Test"])},
  "info|analysis-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide support for the analysis and testing of the needs in your projects. With our expertise, we understand your needs in order to reach the project you target and we offer project solutions suitable for them. We perform end-to-end testing of your software and contribute to the creation of reliable products by pre-testing possible problems."])},
  "info|project-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Management"])},
  "info|project-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We help you plan the resources, budget or time of your projects. By managing needs and dependencies, we make it easier for you to reach your goals by saving time."])},
  "references|heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refrences"])},
  "team|heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
  "form|heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "form|name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Surname *"])},
  "form|contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Info *"])},
  "form|notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your request or suggestion *"])},
  "form|send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "form|required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
  "form|max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Max ", _interpolate(_named("maxNum")), " characters"])}
}