<template>
  <intro-component />
  <info-component />
  <references-component />
  <team-component />
  <form-component />
  <footer-component />
</template>

<script>
import { defineComponent } from 'vue';

import FooterComponent from "../components/Footer.vue";
import FormComponent from "../components/Form.vue";
import InfoComponent from "../components/Info.vue";
import IntroComponent from "../components/Intro.vue";
import ReferencesComponent from "../components/References.vue";
import TeamComponent from "../components/Team.vue";

export default defineComponent({
  name: 'HomeView',

  components: {
    IntroComponent,
    InfoComponent,
    ReferencesComponent,
    TeamComponent,
    FormComponent,
    FooterComponent
  },
});
</script>
